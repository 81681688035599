








import { ScrollModule } from "@/modules/base/store/scroll.module";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class AppMain extends Vue {
  get isDesktopSidebarVisible() {
    return ScrollModule.isDesktopSidebarVisible;
  }
}
