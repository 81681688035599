import Vue from "vue";
import VueI18n from "vue-i18n";
import { LocaleMessages } from "vue-i18n/types";
import { LocaleService } from "@/services/locale.service";
import { Config } from "@/config";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("../locales", true, /[A-Za-z0-9-_,\s]+\.ts$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

export default new VueI18n({
  locale: LocaleService.getLocale() || "ru",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ru",
  messages: loadLocaleMessages(),
  silentTranslationWarn: Config.isProduction,
});
