














































































import { Component, Vue } from "vue-property-decorator";
import { BasketModule } from "../store/basket.module";
import { Product, ProductPrice } from "../@types/product.type";

import ConfirmOrder from "./ConfirmOrder.vue";
import { UserContactsDto } from "../@types/order.dto";
import { ProductsService } from "../api/products.service";
import { WithLoadingAnimation } from "@/utils/decorators";

@Component({ components: { ConfirmOrder } })
export default class BasketDialog extends Vue {
  isDialogVisible = false;
  step = 1;

  // VUEX DATA
  get selectedItems() {
    return BasketModule.selectedProducts;
  }
  get overallPrice() {
    return BasketModule.overallPrice;
  }
  get selectedItemsCount() {
    return BasketModule.dishesCount;
  }
  get isMobile() {
    return this.$vuetify.breakpoint.xs;
  }
  get headerText() {
    if (this.step === 1) return "Корзина";
    else if (this.step === 2) return "Оформление заказа";
    else return "Заказ оформлен";
  }

  // BASE
  async show() {
    this.isDialogVisible = true;
  }

  @WithLoadingAnimation
  async onCancel() {
    if (this.step === 3) {
      return window.location.reload();
    }

    this.hideDialog();
  }
  hideDialog() {
    this.isDialogVisible = false;
  }
  onNextStep() {
    this.step++;
  }
  onBackPressed() {
    if (this.step === 1) return this.hideDialog();
    else this.step--;
  }

  // FUNCTIONAL
  onIncrementCount(product: Product, selection: ProductPrice) {
    selection.userSelectionCount++;
    product.overallUserSelectionCount++;
  }
  onDecrementCount(product: Product, selection: ProductPrice) {
    selection.userSelectionCount--;
    product.overallUserSelectionCount--;
  }
  async onConfirm(userContacts: UserContactsDto) {
    await ProductsService.sendOrder({ totalCount: this.overallPrice, items: this.selectedItems }, userContacts);

    this.onNextStep();
  }
}
