
















































import { Component } from "vue-property-decorator";

import PhoneMaskVue from "@/components/ui/masks/PhoneMask.vue";
import FormValidator from "@/mixins/FormValidator";

import { UserContactsDto } from "../@types/order.dto";
import { CitiesModule } from "../store/cities.module";
import { BasketModule } from "../store/basket.module";

@Component({ components: { PhoneMaskVue } })
export default class ConfirmOrder extends FormValidator {
  name = "";
  mobileNumber: string = "";
  city = "";
  alternativeCity: Nullable<string> = null;
  address = "";

  withDelivery = true;

  isFormValid = false;

  formRef = "formRef";

  async mounted() {
    if (!this.cities) await CitiesModule.getCities();
  }

  get cities() {
    return CitiesModule.cities;
  }

  get priceForSelectedCity() {
    if (!this.city) return null;
    const city = this.cities.find(currentCity => currentCity.name == this.city);
    return city?.delivery_price;
  }

  get priceWithDelivery() {
    if (!this.priceForSelectedCity) return null;
    return BasketModule.overallPrice + this.priceForSelectedCity;
  }

  get payload(): UserContactsDto {
    return {
      name: this.name,
      mobileNumber: this.mobileNumber,
      city: this.alternativeCity ? this.alternativeCity : this.city,
      address: this.address,
      priceForSelectedCity: this.priceForSelectedCity as number,
      priceWithDelivery: this.priceWithDelivery as number,
      withDelivery: this.withDelivery,
    };
  }

  onNextStep() {
    if (!this.validateForm()) return;

    this.$emit("onConfirm", this.payload);
  }
}
