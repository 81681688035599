












import { Component, Vue } from "vue-property-decorator";
import CubeSpin from "vue-loading-spinner/src/components/RotateSquare2.vue";
// import Spinner from "../ui/Spinner.vue";

import BaseModule from "@/store/base.module";

@Component({
  name: "AppProgress",
  components: { CubeSpin },
})
export default class extends Vue {
  get isLoading() {
    return BaseModule.isLoading;
  }
}
