












































import { Component, Vue } from "vue-property-decorator";

import { BasketModule } from "@/modules/dishes/store/basket.module";
import { ScrollModule } from "@/modules/base/store/scroll.module";

import Basket from "@/modules/dishes/components/Basket.vue";

@Component({
  name: "AppHeader",
  components: { Basket },
})
export default class AppNavbar extends Vue {
  get isHeaderVisible() {
    return this.$route.name !== "Signin";
  }
  get selectedItemsCount() {
    return BasketModule.dishesCount;
  }
  get isDesktopSidebarVisible() {
    return ScrollModule.isDesktopSidebarVisible;
  }
  get links() {
    return [
      { icon: "mdi-map-marker-radius", href: "https://go.2gis.com/idpt1o", text: "Алматы, Шевченко 121" },
      { icon: "mdi-whatsapp", href: "https://api.whatsapp.com/send?phone=77027877920", text: "Whatsapp", appendClass: "mx-4" },
      { icon: "mdi-instagram", href: "https://www.instagram.com/ezh.prines", text: "ezh.prines" },
    ];
  }

  imageContainer: any = null;

  $refs: {
    basketRef: Basket;
  };

  onOpenLink(href: string) {
    window.open(href, "blank");
  }
  onOpenBasketClicked() {
    this.$refs.basketRef.show();
  }
  openManagementPage() {
    this.$router.push({ name: "Management" });
  }

  // LIFECYCLE HOOKS
  mounted() {
    this.imageContainer = document.getElementById("header-img");
  }
  created() {
    window.addEventListener("scroll", this.handleScroll);
  }
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // SCROLL HANDLERS
  handleScroll() {
    const isMainHeaderImageVisible = this.checkVisible(this.imageContainer);
    if (isMainHeaderImageVisible) {
      if (!ScrollModule.isDesktopSidebarVisible) return;
      ScrollModule.setDesktopSidebarVisible(false);
    } else {
      if (ScrollModule.isDesktopSidebarVisible) return;
      ScrollModule.setDesktopSidebarVisible(true);
    }
  }
  checkVisible(elm) {
    const rect = elm.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }
}
