import ru from "./ru";

/**
 * Привязав тип объектов перевода их получится сделать
 * идентичными.
 *
 * Пример: добавили перевод в одном объекте, а в другом такого поля нет.
 * Тогда коммит не произойдет, так как проверка типов не пройдет
 */
const locale: Omit<typeof ru, "$vuetify"> = {
  ru: "RU",
  kk: "KZ",
  ru_KZ: "Русский",
  kk_KZ: "Казахский",
  hello: "kkPrivet",
  formValidator: {
    required: "Міндетті түрде толтырылуы қажет жол",
    stringMax: "Жолдың максималды ұзындығы {max} таңба",
    stringMin: "Жолдың минималды ұзындығы {min} таңба",
    bin: "БСН форматы қате",
    iin: "ЖСН форматы қате",
    email: "Қате email енгізілген",
    phone: "Неправильный формат номера телефона",
  },
};

export default locale;
